<template>
  <div>
    <div v-if="type=='publish'" style="width:100%; padding:0; color:#57C7FF;"  class="font-weight-black body-1 mb-1">
      -   ナビゲーションの公開設定 -
    </div>
    <div v-else-if="type=='navi'" style="width:100%; padding:0; color:#57C7FF;"  class="font-weight-black body-1 mb-1">
      -   ナビゲーションの操作 -
    </div>
      <div v-else style="width:100%; padding:0; color:#57C7FF;"  class="font-weight-black body-1 mb-1">
      - {{item.title}}のアップロード -
    </div>

   
    <v-container class="text-left mx-1 mt-3 body-2 font-weight-bold">
      <!-- ナビゲーション -->
      <v-row class="mx-3" v-if="type=='navi'">
         <v-list class="d-flex py-1 "  >
          <span class="font-weight-bold">・</span>
          <div>
            <a  href="https://apps.apple.com/jp/app/%E3%83%8A%E3%83%AC%E3%82%B3%E3%83%A0vr/id1579268145" style="color:#57C7FF;">ナレコムVR</a>でナビゲーションは作成できます
            <div class="d-flex caption  mt-1"> 
              <div>   
                本サイトでは作成できません
              </div>
            </div>
          </div>
        </v-list>
        <v-list class="d-flex py-2 "  >
          <span class="font-weight-bold">・</span>
          <div>
            ナビの名前をクリックするとナビの詳細ページに移動します
            <div class="caption ml-2 my-1 ">
              <!-- <span class="font-weight-bold ">＜ナビゲーションの詳細＞</span> -->
                <p class="ma-0 ml-2 pa-0">詳細ページでは以下のことが行えます</p>
              <li class="ml-3"><span class="font-weight-bold">サムネイル、名前、説明の変更</span></li>
              <li class="ml-3"><span class="font-weight-bold">公開設定の変更</span></li>
                <v-list class="ml-8 my-0 pa-0">・非公開   - 自分のみ閲覧可能</v-list>
                <v-list class="ml-8 my-0 pa-0">・限定公開 - Emailを登録した人のみ閲覧可能</v-list>
                <v-list class="ml-8 my-0 pa-0">・全体公開 - 誰でも閲覧可能</v-list>
              <li class="ml-3"><span class="font-weight-bold">コンテンツの検索</span></li>
                <v-list class="ml-8 my-0 pa-0">ウェイポイント>コンテンツ><v-icon small class="pb-1" color="grey darken-2">mdi-table-search</v-icon>をクリックするとコンテンツがあるページに移動できます</v-list> 
              
            </div>
          </div>
        </v-list>
        <v-list class="d-flex py-2 "  >
          <span class="font-weight-bold">・</span>
          <div>
            <v-icon dense color="grey darken-2">mdi-qrcode</v-icon>クリックでQRコードが表示されます
            <div class="d-flex caption  my-1"> 
              <div>   
                 <a  href="https://apps.apple.com/jp/app/%E3%83%8A%E3%83%AC%E3%82%B3%E3%83%A0vr/id1579268145" style="color:#57C7FF;">ナレコムVR</a>で読み込むことでナビを表示できます
              </div>
            </div>
          </div>
        </v-list>
      </v-row>

        <!-- 公開設定 -->
      <v-row class="mx-3" v-if="type=='publish'">
    
        <v-list class="d-flex py-2 "  >
          <div class="font-weight-regular">
              <li class="ml-3"><span class="font-weight-bold">公開設定の変更</span></li>
                <v-list class="ml-8 my-0 pa-0">・非公開   - 自分のみ閲覧可能</v-list>
                <v-list class="ml-8 my-0 pa-0">・限定公開 - Emailを登録した人のみ閲覧可能</v-list>
                <v-list class="ml-8 my-0 pa-0">・全体公開 - 誰でも閲覧可能</v-list>
         
          </div>
        </v-list>
      </v-row>

       <!-- コンテンツのアップロード -->
       <!-- URL以外 -->
      <v-row class="mx-3" v-else-if="type!='url'">
        <v-list class="d-flex py-2 "  >
          <span class="font-weight-bold">・</span>
          <div>
            一度にアップロードできるのは10個までです
          </div>
        </v-list>
        <v-list class="d-flex py-2 "  >
          <span class="font-weight-bold">・</span>
          <div>
            容量制限を超えた場合、アップロードはできません
          </div>
        </v-list>
        <v-list class="d-flex py-2 " v-if="type!='url'">
          <span class="font-weight-bold">・</span>
          <div v-if="type=='sound'">
            <v-icon  small  color="grey darken-1" class="pb-1">mdi-arrow-right-drop-circle-outline</v-icon>をクリックすることで音声の再生が再生されます
            <div class="d-flex caption  my-1">＊
              <div>   
                <v-icon small color="narekomu" >mdi-pause-circle-outline</v-icon>を押すことで、音楽の再生を停止できます
              </div>
            </div>
            <div class="d-flex caption  my-1">＊
              <div>   
                ページ下部に表示される音楽プレイヤーで音声の操作が可能です
              </div>
            </div>
          </div>
          <div  v-else>
              サムネイルをクリックすることで{{item.title}}の確認が可能です
            <div  v-if="type=='object'" class="caption ml-2">
              ＜操作方法＞
              <li class="ml-3"><span class="font-weight-bold">スクロール</span> - モデルの拡大縮小</li>
              <li class="ml-3"><span class="font-weight-bold">ドラッグ</span> - モデルの回転</li>
              
            </div>
            <div v-if="type=='pdf'||type=='movie'" class="d-flex caption  my-1">＊<div>{{item.title}}が表示されない場合、
              <v-icon  small  color="narekomu" class="pb-1">mdi-open-in-new</v-icon>を押して、新しいタブで表示できます。
              </div>
            </div>
          </div>
        </v-list>

        <v-list class="d-flex py-2 "  v-if="type=='object'||type=='pdf'||type=='movie'" >
          <span class="font-weight-bold">・</span>
          <div>
            サムネイルはモデルアップロード時に自動生成されます
            <div class="d-flex caption  my-1">＊<div>サムネイルが生成されない場合があります。その場合サムネイルはデフォルトのものになります。</div></div>
              <div class="d-flex caption">＊<div>サムネイルはメニューから変更可能です。 また、メニューから{{item.title}}のサムネイルを戻すことが可能です。</div></div>
          </div>
        </v-list>
      </v-row>

      <!-- URL -->
      <v-row class="mx-3" v-else>
        <v-list class="d-flex py-2 " >
          <span class="font-weight-bold">・</span>
          <div>
           URLをクリックすると新しいタブでページを開きます
          </div>
        </v-list>
        <v-list class="d-flex py-2 "  >
          <span class="font-weight-bold">・</span>
          <div>
            個数制限を超えた場合、アップロードはできません
          </div>
        </v-list>
      </v-row>
    </v-container>

<!--/////////////////////////////////////////////////////////////////////////////////////////////////////// -->

          
  </div>
  

</template>

<script>



  export default {
    name: 'CardTutorial',
    props:["type","item"],
    data: () => ({
      
      // links:{
      //   content:[
      //     "コンテンツのアップロード",
      //     "URLのアップロード",
      //     "コンテンツの名前変更",
      //     "コンテンツの削除",
      //     "コンテンツのサムネイル変更",
      //     "3Dモデルの表示",
      //     "PDFの表示",
      //     "音楽の再生",
      //     "動画の再生",
      //   ]
      // },
      // items:{
      //   //トップページ
      //   top: [ 
      //     {
      //       src: require('@/assets/tuto1.png'),
      //     },
      //     {
      //       src: require('@/assets/tuto2.png'),
      //     },
      //     {
      //       src: require('@/assets/tuto3.png'),
      //     },
      //     {
      //       src: require('@/assets/tuto4.png'),
      //     },
      //     {
      //       src: require('@/assets/tuto5.png'),
      //     },
      //   ],
      //   //ナビページ
      //   navi: [
      //     {
      //       src: require('@/assets/navi_tuto1.png'),
      //     },
      //     {
      //       src: require('@/assets/navi_tuto2.png'),
      //     },
      //     {
      //       src: require('@/assets/navi_tuto3.png'),
      //     },
      //     {
      //       src: require('@/assets/navi_tuto4.png'),
      //     },
      //     {
      //       src: require('@/assets/navi_tuto5.png'),
      //     },
      //   ],
      //   //コンテンツ
      //   content: [
      //     {
      //       src: require('@/assets/c_tuto.png'),
      //     },
      //     {
      //       src: require('@/assets/c_tuto2.png'),
      //     },
      //     {
      //       src: require('@/assets/c_tuto3.png'),
      //     },
      //     {
      //       src: require('@/assets/c_tuto4.png'),
      //     },
      //     {
      //       src: require('@/assets/c_tuto5.png'),
      //     },
      //     {
      //       src: require('@/assets/c_tuto6.png'),
      //     },
      //     {
      //       src: require('@/assets/c_tuto7.png'),
      //     },
      //     {
      //       src: require('@/assets/c_tuto8.png'),
      //     },
      //     {
      //       src: require('@/assets/c_tuto9.png'),
      //     },
      //     {
      //       src: require('@/assets/c_tuto10.png'),
      //     },
      //   ],
      // },
      

    }),

  
    watch:{
        type:function(){
          this.model = 0;
        }
      }

    
   
  }
</script>


<style scoped>


  .v-btn .v-btn__content .v-icon {
        color: black;
  }


</style>

